.modal-container {
	position: fixed;
	width: 400px;
	height: 400px;
	background: rgb(245, 245, 245);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	box-shadow: 0 2px 0 10000px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
}

.modal-container-for-user-deletion {
	position: fixed;
	width: 400px;
	height: 300px;
	background: rgb(245, 245, 245);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	box-shadow: 0 2px 0 10000px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	background: rgba(0, 0, 0, 0.03);
}

.modal-header-close-btn {
	color: rgba(0, 0, 0, 0.5);
}

.modal-header-close-btn:hover {
	color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

.modal-footer {
	display: flex;
	justify-content: space-around;
	background: rgba(0, 0, 0, 0.03);
	height: 90px;
	padding: 0;
}
.modal-footer button {
	margin: 0;
}

.modal-body {
	overflow-y: scroll;
	background-color: white;
}

.modal-body label {
	margin: 10px;
}
