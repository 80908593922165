.pagination-container {
	background: transparent;
	margin-top: 50px;
	margin-bottom: 70px;
	display: flex;
	justify-content: center;
}

.pagination-list {
	display: inline-block;
}

.pagination-list span {
	font-size: 1.7rem;
	font-weight: 500;
	background: transparent;
	padding: 10px 20px;
}

.pagination-list span[value] {
	cursor: pointer;
}

.pagination-list span:hover[value],
.previous:hover,
.next:hover {
	background: rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}
