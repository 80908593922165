.tag1,
.tag2,
.tag3 {
  border: 1px solid black;
  padding-inline: 20px;
  border-radius: 10px 0 0 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.tag2 {
  border-radius: 0 0 0 0;
}

.tag3 {
  border-radius: 0 10px 10px 0;
}

.container {
  height: 100vh;
  overflow-y: scroll;
}

.table_wrapper {
  height: 78vh;
  margin-top: 20px;
}
