.container {
  height: fit-content;
  width: 100%;
}

.table_head {
  padding-left: 50px;
  font-size: 0.8rem;
}

.table_head_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-overflow: ellipsis;
}

.table_body_row {
  background: white;
  margin-bottom: 5px;
  min-height: 50px;
  height: fit-content;
  padding-left: 50px;
  border-radius: 5px;
}

.table_body_row p {
  height: 100%;
}
